@import "../../../../assets/less/variables";

.ui.form{
  .ri-field {
    input {
      border: 1px solid @input-border-color;
      border-radius: @input-border-radius;
      padding: @input-padding;
    }

    textarea {
      height: 6em;
    }

    .label {
      color: #696974;
      display: inline-block;
      margin-bottom: 6px;
      .required{
        color:red;
      }
    }
    .validation-message {
      color: red;
      display: inline-block;
      margin-top: 2px;
    }
  }
}

.radio-button{
  display: inline-block;
  margin-right: 50px;
}

