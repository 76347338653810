@import "../../../assets/less/variables";

.button.primary{
  .icon{
    opacity: 1 !important;
  }
}
.secondary-btn{
  background-color: @secondary-background-color !important;
  color: @secondary-color !important;
  border: 1px solid @secondary-border-color !important;
}

.add-button{
  background-color: transparent;
  border-radius: 0px;
  border:0px solid transparent;
  outline: none;
  cursor: pointer;
  color:#3751FF;
  font-weight: bold;
  font-size: 14px;
  padding: 1em 1.5em;
}

.ri-button{
  border-radius: @default-radius !important;
}

.ri-clear-button{
  background-color: transparent;
  border-radius: 0px;
  border:0px solid transparent;
  outline: none;
  cursor: pointer;
}
