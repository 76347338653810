@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';

.noty_layout{
  .noty_bar{
    border-radius: 8px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
    .noty_progressbar{
      display:none !important;
    }
  }
}
