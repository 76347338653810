.login-form-container {
  margin-top: 0px !important;
  background: #f5f5f7;
}
.login-form-container .image-container img {
  height: 45px !important;
  width: auto;
  margin: 30px auto !important;
}
.login-form-container .footer {
  margin-top: 60px;
}
.login-form-container .footer span {
  color: #696974;
  font-size: 14px;
}
.login-form-container .footer img {
  height: 33px;
  width: auto;
  margin: 15px auto !important;
}
.login-form-container .login-form .segment {
  padding: 30px !important;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-color: transparent;
  text-align: left;
}
.login-form-container .login-form .segment:after {
  display: none;
}
.login-form-container .login-form .segment .checkbox {
  display: block;
  margin-bottom: 20px;
}
.login-form-container .login-form h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.login-form-container .login-form button {
  background-color: #FF373F;
  border-radius: 8px;
  padding: 1em 1.5em;
}
