// Variables
@import "../../../assets/less/variables";
// Usage
.login-form-container {
  margin-top: 0px !important;
  background: @background-color;
  .image-container{
    img{
      height: 45px !important;
      width: auto;
      margin:30px auto !important;
    }
  }
  .footer{
    margin-top: 60px;
    span{
      color:#696974;
      font-size: 14px;
    }
    img{
      height: 33px;
      width: auto;
      margin:15px auto !important;
    }
  }
  .login-form {
    .segment {
      padding: 30px !important;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border-color: transparent;
      text-align: left;
      &:after{
        display: none;
      }
      .checkbox{
        display: block;
        margin-bottom: 20px;
      }
    }


    h2 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 30px;
    }

    button {
      background-color: @primary-color;
      border-radius: @default-radius;
      padding: @default-padding;
    }
  }
}
