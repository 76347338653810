.ui.form .ri-field input {
  border: 1px solid #E2E2EA;
  border-radius: 8px;
  padding: 1em 1.5em;
}
.ui.form .ri-field textarea {
  height: 6em;
}
.ui.form .ri-field .label {
  color: #696974;
  display: inline-block;
  margin-bottom: 6px;
}
.ui.form .ri-field .label .required {
  color: red;
}
.ui.form .ri-field .validation-message {
  color: red;
  display: inline-block;
  margin-top: 2px;
}
.radio-button {
  display: inline-block;
  margin-right: 50px;
}
