.button.primary .icon {
  opacity: 1 !important;
}
.secondary-btn {
  background-color: white !important;
  color: #696974 !important;
  border: 1px solid #E2E2EA !important;
}
.add-button {
  background-color: transparent;
  border-radius: 0px;
  border: 0px solid transparent;
  outline: none;
  cursor: pointer;
  color: #3751FF;
  font-weight: bold;
  font-size: 14px;
  padding: 1em 1.5em;
}
.ri-button {
  border-radius: 8px !important;
}
.ri-clear-button {
  background-color: transparent;
  border-radius: 0px;
  border: 0px solid transparent;
  outline: none;
  cursor: pointer;
}
